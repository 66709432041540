<template>
    <div>
        <ts-panel>
            <ts-panel-wrapper>
                <div class="tw-flex tw-justify-between">
                    <div class="tw-justify-start">
                        <h4 class="tw-mb-0">{{ $t("system.repaymentItemPriority") }}</h4>
                    </div>
                    <div class="tw-text-red-600" v-if="errors.has('repaymentItemPriority')">
                        {{ errors.first("repaymentItemPriority") }}
                    </div>
                </div>
                <div
                    class="tw-mt-2  tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-60 tw-overflow-auto"
                >
                    <ts-table
                        :columns="columns"
                        :records="resources"
                    >
                        <template v-slot="{ index, record }">
                            <td>
                                <input
                                    v-model="record.repayment_item"
                                    type="text"
                                    class="form-control-plaintext"
                                    readonly
                                />
                            </td>
                            <td width="30%" class="tw-text-center">
                                <input
                                    v-model.number="record.repayment_order"
                                    type="number"
                                    class="form-control tw-text-center"
                                />
                            </td>
                            <td width="20%" class="tw-text-center">
                                <i-switch
                                    v-show="index != 0"
                                    v-tooltip="$t('system.isAllowPenalty')"
                                    size="small"
                                    v-model="record.allow_penalty"
                                >
                                </i-switch>
                            </td>
                        </template>
                    </ts-table>
                </div>
            </ts-panel-wrapper>
        </ts-panel>
    </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { orderBy } from 'lodash'

export default {
    name: "generalSetting",
    props: ["value", "validate"],
    data() {
        return {
            errors: new Errors(),
            model: {}
        };
    },
    computed: {
        columns() {
            return [
                { name: this.$t("system.repaymentItem") },
                { name: this.$t("system.repaymentOrder"), style: 'text-align: center' },
                { name: this.$t("system.allowPenalty"), style: 'text-align: center' }
            ];
        },
        resources(){
            return orderBy(this.model.repaymentItemPriority, ['repayment_order']);
        }
    },
    created() {
        this.model = this.value;
    },
    watch: {
        validate: function(error) {
            this.errors = new Errors(error.errors);
        }
    }
};
</script>
<style lang="css" scoped>
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    /* border-color: #e2e7eb; */
    padding: 4px 2px !important;
    vertical-align: middle;
}
.table > :not(:first-child) {
    border-top: 1px solid grey;
}
</style>
