var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column overflow-hidden h-100"},[_c('vue-custom-scrollbar',{staticClass:"app-content-padding flex-grow-1 overflow-hidden h-100"},[_c('ts-page-title',{attrs:{"title":_vm.$t('system.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('system.pageTitle'),
                    href: '/admin/systems'
                },
                {
                    text: _vm.$t('create'),
                    active: true
                }
            ]}}),_c('div',{staticClass:"demo-spin-article"},[_c('div',{staticClass:"row tw-mb-5"},[_c('div',{staticClass:"col-md-5"},[_c('general-setting',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{staticClass:"col-md-7"},[_c('overdue-penalty',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)]),_c('div',{staticClass:"row tw-mb-5"},[_c('div',{staticClass:"col-md-12"},[_c('repayment-item-priority',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])]),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1),_c('div',{staticClass:"app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh",staticStyle:{"background-color":"#e9ecef"},attrs:{"id":"footer"}},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: _vm.prevRoute.name })}}},[_vm._v(" "+_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting_new},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("save")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }