var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-justify-start"},[_c('h4',{staticClass:"tw-mb-0"},[_vm._v(_vm._s(_vm.$t('system.general')))])])]),_c('div',{staticClass:"tw-mt-2  tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-72 tw-overflow-auto"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"},[_c('strong',[_vm._v(" - Number of day allow reschedule")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.num_day_allow_reschedule),expression:"model.num_day_allow_reschedule",modifiers:{"number":true}}],staticClass:"tw-border tw-rounded-md tw-text-right tw-py-1 tw-w-20 tw-outline-none",class:{
                                'is-invalid': _vm.errors.has(
                                    'num_day_allow_reschedule'
                                )
                            },attrs:{"type":"number","min":"0"},domProps:{"value":(_vm.model.num_day_allow_reschedule)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "num_day_allow_reschedule", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('num_day_allow_reschedule'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('num_day_allow_reschedule'))+" ")]):_vm._e()])]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"},[_c('strong',[_vm._v(" - Reschedule penalty rate")]),_c('div',[_c('InputNumber',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'reschedule_penalty_rate'
                                )
                            },attrs:{"max":100,"formatter":function (value) { return (value + "%"); },"parser":function (value) { return value.replace('%', ''); },"min":0},model:{value:(_vm.model.reschedule_penalty_rate),callback:function ($$v) {_vm.$set(_vm.model, "reschedule_penalty_rate", $$v)},expression:"model.reschedule_penalty_rate"}})],1)]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"},[_c('strong',[_vm._v(" - Pay off penalty rate")]),_c('div',[_c('InputNumber',{class:{
                                'ivu-form-item-error': _vm.errors.has(
                                    'pay_off_penalty_rate'
                                )
                            },attrs:{"max":100,"formatter":function (value) { return (value + "%"); },"parser":function (value) { return value.replace('%', ''); },"min":0},model:{value:(_vm.model.pay_off_penalty_rate),callback:function ($$v) {_vm.$set(_vm.model, "pay_off_penalty_rate", $$v)},expression:"model.pay_off_penalty_rate"}})],1)]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"},[_c('strong',[_vm._v(" - Decimal digit")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.decimal_digit),expression:"model.decimal_digit",modifiers:{"number":true}}],staticClass:"tw-border tw-rounded-md tw-text-right tw-py-1 tw-w-20 tw-outline-none",class:{
                                'is-invalid': _vm.errors.has(
                                    'reschedule_penalty_rate'
                                )
                            },attrs:{"type":"number","min":"0"},domProps:{"value":(_vm.model.decimal_digit)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "decimal_digit", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('reschedule_penalty_rate'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('reschedule_penalty_rate'))+" ")]):_vm._e()])]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-1 tw-outline-none tw-mb-1"},[_c('strong',[_vm._v(" - Idle Time Out (Minute)")]),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.idle_time_out),expression:"model.idle_time_out",modifiers:{"number":true}}],staticClass:"tw-border tw-rounded-md tw-text-right tw-py-1 tw-w-20 tw-outline-none",class:{
                                'is-invalid': _vm.errors.has('idle_time_out')
                            },attrs:{"type":"number","min":"0"},domProps:{"value":(_vm.model.idle_time_out)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "idle_time_out", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('idle_time_out'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first('idle_time_out'))+" ")]):_vm._e()])]),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-whitespace-nowrap tw-border tw-rounded-md tw-text-right tw-p-2 tw-outline-none tw-mb-1"},[_c('strong',[_vm._v(" - Is approve transaction require")]),_c('div',[_c('i-switch',{model:{value:(_vm.model.is_approve_transaction_required),callback:function ($$v) {_vm.$set(_vm.model, "is_approve_transaction_required", $$v)},expression:"model.is_approve_transaction_required"}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }