var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-panel',[_c('ts-panel-wrapper',[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',{staticClass:"tw-justify-start"},[_c('h4',{staticClass:"tw-mb-0"},[_vm._v(" "+_vm._s(_vm.$t('system.overduePenalty'))+" ")])]),(_vm.errors.has('overduePenalty'))?_c('div',{staticClass:"tw-text-red-600"},[_vm._v(" "+_vm._s(_vm.errors.first('overduePenalty'))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-mt-2 tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-h-72 tw-overflow-auto"},[_c('ts-table',{attrs:{"records":_vm.resources},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('system.overdueType'))+" ")]),_c('th',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('system.overdueFrom'))+" ")]),_c('th',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('system.overdueTo'))+" ")]),_c('th',{staticClass:"tw-text-center"},[_vm._v(" "+_vm._s(_vm.$t('system.penaltyRate'))+" ")]),_c('th',{staticClass:"tw-text-center"},[_c('a',{staticClass:"text-primary",attrs:{"href":"#"}},[_c('i',{staticClass:"fa fa-plus",on:{"click":function($event){$event.preventDefault();return _vm.addNewRow.apply(null, arguments)}}})])])])])]},proxy:true},{key:"default",fn:function(ref){
var record = ref.record;
return [_c('td',{staticClass:"tw-text-center",attrs:{"width":"13%"}},[_c('Select',{class:{
                                    'is-invalid': _vm.errors.has('overdue_type')
                                },model:{value:(record.overdue_type),callback:function ($$v) {_vm.$set(record, "overdue_type", $$v)},expression:"record.overdue_type"}},[_c('i-Option',{attrs:{"value":"day"}},[_vm._v("Day")]),_c('i-Option',{attrs:{"value":"amount"}},[_vm._v("Amount")])],1)],1),_c('td',{staticClass:"tw-text-center",attrs:{"width":"20%"}},[(record.overdue_type == 'day')?_c('InputNumber',{class:{
                                    'ivu-form-item-error':
                                        _vm.errors.has('overdue_from')
                                },staticStyle:{"width":"260px"},attrs:{"min":0},model:{value:(record.overdue_from),callback:function ($$v) {_vm.$set(record, "overdue_from", _vm._n($$v))},expression:"record.overdue_from"}}):_vm._e(),(record.overdue_type == 'amount')?_c('Poptip',{staticStyle:{"width":"100%"},attrs:{"trigger":"focus"}},[_c('div',{staticClass:"tw-w-full"},[_c('InputNumber',{class:{
                                            'ivu-form-item-error':
                                                _vm.errors.has('overdue_from')
                                        },staticStyle:{"width":"260px"},attrs:{"min":0},model:{value:(record.overdue_from),callback:function ($$v) {_vm.$set(record, "overdue_from", _vm._n($$v))},expression:"record.overdue_from"}})],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(record.overdue_from ? _vm.formatNumber( record.overdue_from, 2 ) : _vm.$t('loanRecovery.enterNumber'))+" ")])]):_vm._e()],1),_c('td',{staticClass:"tw-text-center",attrs:{"width":"20%"}},[(record.overdue_type == 'day')?_c('InputNumber',{class:{
                                    'ivu-form-item-error':
                                        _vm.errors.has('overdue_to')
                                },staticStyle:{"width":"260px"},attrs:{"min":0},model:{value:(record.overdue_to),callback:function ($$v) {_vm.$set(record, "overdue_to", _vm._n($$v))},expression:"record.overdue_to"}}):_vm._e(),(record.overdue_type == 'amount')?_c('Poptip',{staticStyle:{"width":"100%"},attrs:{"trigger":"focus"}},[_c('div',{staticClass:"tw-w-full"},[_c('InputNumber',{class:{
                                            'ivu-form-item-error':
                                                _vm.errors.has('overdue_to')
                                        },staticStyle:{"width":"260px"},attrs:{"min":0},model:{value:(record.overdue_to),callback:function ($$v) {_vm.$set(record, "overdue_to", _vm._n($$v))},expression:"record.overdue_to"}})],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(record.overdue_to ? _vm.formatNumber( record.overdue_to, 2 ) : _vm.$t('loanRecovery.enterNumber'))+" ")])]):_vm._e()],1),_c('td',{staticClass:"tw-text-center",attrs:{"width":"20%"}},[_c('div',{staticClass:"tw-flex"},[(record.penalty_rate_type == '%')?_c('InputNumber',{class:{
                                        'ivu-form-item-error':
                                            _vm.errors.has('penalty_rate')
                                    },staticStyle:{"width":"100%","text-align":"right"},attrs:{"max":100,"min":0},model:{value:(record.penalty_rate),callback:function ($$v) {_vm.$set(record, "penalty_rate", _vm._n($$v))},expression:"record.penalty_rate"}}):_vm._e(),(record.penalty_rate_type == '$')?_c('Poptip',{staticStyle:{"width":"100%"},attrs:{"trigger":"focus"}},[_c('div',{staticClass:"tw-w-full"},[_c('InputNumber',{staticClass:"tw-text-right",class:{
                                                'ivu-form-item-error':
                                                    _vm.errors.has(
                                                        'penalty_rate'
                                                    )
                                            },staticStyle:{"width":"100%"},attrs:{"min":0},model:{value:(
                                                record.penalty_rate
                                            ),callback:function ($$v) {_vm.$set(record, "penalty_rate", _vm._n($$v))},expression:"\n                                                record.penalty_rate\n                                            "}})],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(record.penalty_rate ? _vm.formatNumber( record.penalty_rate, 2 ) : _vm.$t( 'loanRecovery.enterNumber' ))+" ")])]):_vm._e(),_c('div',[_c('Select',{staticStyle:{"width":"70px"},on:{"on-change":function($event){record.penalty_rate = 0}},model:{value:(record.penalty_rate_type),callback:function ($$v) {_vm.$set(record, "penalty_rate_type", $$v)},expression:"record.penalty_rate_type"}},[_c('i-Option',{attrs:{"value":"%"}},[_vm._v("%")]),_c('i-Option',{attrs:{"value":"$"}},[_vm._v("$")])],1)],1)],1)]),_c('td',{staticClass:"tw-text-center",attrs:{"width":"5%"}},[_c('a',{staticClass:"text-danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeRow(record)}}},[_c('i',{staticClass:"fa fa-trash"})])])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }